<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="form-group">
      <label>Shop<sup>*</sup> </label>
      <div class="form-group__content">
        <select class="form-control" id="shop" v-model="form.shop_id">
          <option v-for="shop in shops" :key="shop.id" :value="shop.id">{{
            shop.name
          }}</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label>Products File<sup>*</sup> </label>
      <div class="form-group__content">
        <input
          class="form-control"
          type="file"
          @change="handleFileUpload($event)"
          placeholder=""
        />
        <small v-if="errors && errors.file" class="phone_error text-danger">
          {{ errors.file[0] }}
        </small>
      </div>
    </div>

    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Submitting..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span
          v-if="isLoading"
          class="spinner-border text-light"
          role="status"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "Upload",
  data() {
    return {
      shops: [],
      form: {
        shop_id: null,
        file: null,
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.getShops();
  },
  methods: {
    getShops() {
      axios.get("/shops/lis").then((response) => {
        this.shops = response.data;
      });
    },
    handleFileUpload($event) {
      this.form.file = event.target.files[0];
    },
    onSubmit() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("shop_id", this.form.shop_id);

      axios
        .post("/products/upload", formData)
        .then((response) => {
          this.$toasted.success("Product uploaded successfully");
          this.isLoading = false;
        })
        .catch((error) => {
          this.$toasted.error("Product upload failed, please try again");
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
